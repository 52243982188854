import { GenericTable, LoadingList, Search } from '@praticabr/ppsa'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import {
	handleFinancialReleaseTypes,
	handleOperationType,
	handleOrderBranchTypes
} from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'

export function OpenOrdersList() {
	const {
		financePosition,
		groupFinancePosition,
		financePositionLoading,
		groupFinancePositionLoading
	} = useContext(ClientDetailsContextElement)
	const [openOrders, setOpenOrders] = useState<any[]>([])

	useEffect(() => {
		if (groupFinancePosition) {
			return setOpenOrders(groupFinancePosition?.openOrders)
		} else if (financePosition && !groupFinancePosition) {
			return setOpenOrders(financePosition.openOrders)
		}
	}, [financePosition, groupFinancePosition])

	function handleFilterResults(value: string | number) {
		if (groupFinancePosition) {
			return setOpenOrders(
				groupFinancePosition.openOrders.filter(
					(sc5: any) =>
						sc5?.c5Cliente.includes(value) || sc5?.c5Num.includes(value)
				)
			)
		} else if (financePosition) {
			return setOpenOrders(
				financePosition.openOrders.filter(
					(sc5: any) =>
						sc5?.c5Cliente.includes(value) || sc5?.c5Num.includes(value)
				)
			)
		}
	}

	function handleOnClickRow(data: any) {
		window.open(
			`${process.env.PUBLIC_URL}/order/${data.c5Filial}/${data?.c5Num}/details`,
			'_blank'
		)
	}

	return (
		<div className="client-finance-details-tab">
			<div className="client-finance-details-tab-header-actions">
				<Search.root>
					<Search.icon>
						<SearchIcon height="100%" width="100%" />
					</Search.icon>
					<Search.input
						onChangeValues={(e) => handleFilterResults(e)}
						placeholder="Buscar por cliente ou pedido..."
					/>
				</Search.root>
			</div>
			<div className="open-orders-table">
				{financePositionLoading || groupFinancePositionLoading ? (
					<LoadingList />
				) : (
					<GenericTable
						onRowClicked={handleOnClickRow}
						data={[
							...(openOrders?.map((order: any) => ({
								...order,
								id: order?.rECNO,
								branch: handleOrderBranchTypes(order?.c5Filial),
								client: `${order?.c5Cliente} - ${order?.c5Lojacli}`,
								order: order?.c5Num,
								financialRelease: handleFinancialReleaseTypes(order?.c5Libfin),
								condPagType: order?.c5Condpag
									? `${order?.c5Condpag} - ${order?.e4Descri}`
									: '-',
								operation: handleOperationType(order?.c6Oper),
								discountOne: order?.c5Descon1,
								discountTwo: order?.c5Descon2,
								discountThree: order?.c5Descon3,
								value: (order?.c6Valor || 0).toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL'
								})
							})) ?? [])
						]}
						columns={[
							{
								key: 'branch',
								header: 'Filial',
								width: '9%'
							},
							{
								key: 'client',
								header: 'Cliente',
								width: '8%'
							},
							{
								key: 'order',
								header: 'Pedido',
								width: '8%'
							},
							{
								key: 'condPagType',
								header: 'Cond. Pagamento',
								width: '12%'
							},
							{
								key: 'operation',
								header: 'Operação',
								width: '12%'
							},
							{
								key: 'financialRelease',
								header: 'Lib. Financeira',
								width: '12%'
							},
							{
								key: 'discountOne',
								header: 'Desconto 1',
								width: '9%'
							},
							{
								key: 'discountTwo',
								header: 'Desconto 2',
								width: '9%'
							},
							{
								key: 'discountThree',
								header: 'Desconto 3',
								width: '9%'
							},
							{
								key: 'value',
								header: 'Valor',
								width: '10%'
							}
						]}
					/>
				)}
			</div>
		</div>
	)
}
