import { Modal, Search } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { Client } from 'module/orderManagement/domain/models/client'
import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { ViewType } from 'utils/Constants'
import { normalizeCGC } from 'utils/Pipe'
import './styles.scss'

interface OrderClientSearchProps {
	onClose: (value: boolean) => void
	onChangeClient: (item: any) => void
}

const OrderClientSearch = ({
	onClose,
	onChangeClient
}: OrderClientSearchProps) => {
	const { viewType } = useContext(DeviceContextElement)
	const { searchClient, availableClientChange } = useContext(
		ClientDetailsContextElement
	)

	const {
		watch,
		setValue,
		formState: { errors }
	} = useForm()

	const watchedOrderCustomer = watch('order_customer')

	useEffect(() => {
		const timeOutId = setTimeout(
			() => searchClient(watchedOrderCustomer?.toUpperCase()),
			500
		)
		return () => clearTimeout(timeOutId)
	}, [watchedOrderCustomer])

	const orderClientSearchHeader = () => {
		return (
			<div className="clients-header-list">
				<div className="clients-details-list">
					<ul className="clients-details-list-item clients-details-list-code">
						<span>Código</span>
					</ul>
					<ul className="clients-details-list-item clients-details-list-company-name">
						<span>Razão Social</span>
					</ul>
					<ul className="clients-details-list-item clients-details-list-cnpj-cpf">
						<span>CPF/CNPJ</span>
					</ul>
				</div>
			</div>
		)
	}

	const orderClientSearchBodyMobile = () => {
		return (
			<div className="clients-body-list">
				{availableClientChange?.map((item: Client, index: number) => {
					return (
						<div
							className="clients-body-item"
							key={index}
							onClick={() => {
								onChangeClient(item)
							}}
						>
							<div className="clients-details-list">
								<ul className="clients-details-list-item clients-details-list-code">
									<span>Código:</span>
									<span>{item?.code}</span>
								</ul>
								<ul className="clients-details-list-item clients-details-list-company-name">
									<span>Razão social:</span>
									<span>{item?.name}</span>
								</ul>
								<ul className="clients-details-list-item clients-details-list-cnpj-cpf">
									<span>CPF/CNPJ:</span>
									<span>{normalizeCGC(item?.cgc)}</span>
								</ul>
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	const orderClientSearchBodyWeb = () => {
		return (
			<div className="clients-body-list">
				{availableClientChange?.map((item: Client, index: number) => {
					return (
						<div
							className="clients-body-item"
							key={index}
							onClick={() => {
								onChangeClient(item)
							}}
						>
							<div className="clients-details-list">
								<ul className="clients-details-list-item clients-details-list-code">
									<span>{item?.code}</span>
								</ul>
								<ul className="clients-details-list-item clients-details-list-company-name">
									<span>{item?.name}</span>
								</ul>
								<ul className="clients-details-list-item clients-details-list-cnpj-cpf">
									<span>{normalizeCGC(item?.cgc)}</span>
								</ul>
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<Modal.root>
			<Modal.header
				title="BUSCAR CLIENTE"
				onClose={() => {
					onClose(false)
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="clients-list-container">
						<div className="client-input-container">
							<div>
								<Search.root>
									<Search.icon>
										<SearchIcon width="100%" height="100%" />
									</Search.icon>
									<Search.input
										placeholder="Buscar cliente"
										onChangeValues={(e: any) => setValue('order_customer', e)}
									/>
								</Search.root>
							</div>
						</div>

						{viewType !== ViewType.MOBILE ? (
							<>
								{orderClientSearchHeader()}
								{orderClientSearchBodyWeb()}
							</>
						) : (
							orderClientSearchBodyMobile()
						)}
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}
export default OrderClientSearch
