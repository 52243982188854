import { EmptyList, LoadingList } from '@praticabr/ppsa'
import not_pendency from 'assets/image/not_pendency.svg'
import pendency from 'assets/image/pendency.svg'
import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import AllOrdersListItem, {
	AllOrdersListItemPendency
} from 'module/orderManagement/models/AllOrdersListItem'
import {
	handleFinancialReleaseTypes,
	handleOrderCategoryType,
	handleOrderStatusTypes,
	handleSalespersonType
} from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './styles.scss'

const OrdersListBody: React.FC = () => {
	const { orderList, isLoading } = useContext(OrderContextElement)

	const handlePendencyStatus = (pendencyList: AllOrdersListItemPendency[]) => {
		const hasPendency = pendencyList?.some(
			(pendency) => pendency.status === '1' || pendency.status === '2'
		)
		return hasPendency ? (
			<img src={pendency} style={{ height: '26px', width: '16px' }} />
		) : (
			<img src={not_pendency} style={{ height: '26px', width: '16px' }} />
		)
	}

	const handleOrderStatus = (partOrder: AllOrdersListItem) => {
		const hasItemToBeBilled = partOrder.itens?.some(
			(pendency) => pendency.invoice === ''
		)
		const hasBilledItens = partOrder.itens?.some(
			(pendency) => pendency.invoice !== ''
		)
		if (hasBilledItens) {
			if (hasItemToBeBilled) {
				return handleOrderStatusTypes('5')
			}
			return handleOrderStatusTypes('6')
		} else if (partOrder.financial_status) {
			return handleFinancialReleaseTypes(partOrder.financial_status)
		}
		return '-'
	}

	const listContent = () => {
		return orderList?.length ? (
			orderList.map((partOrder: AllOrdersListItem, index) => {
				return (
					<NavLink
						className="orders-list-row"
						to={`/order/${partOrder.branch}/${partOrder.order}/details`}
						id={partOrder.order}
						key={`${index}-${partOrder.order}`}
					>
						<ul className="orders-list-item orders-list-legend">
							{handlePendencyStatus(partOrder.pendency_list)}
						</ul>
						<ul className="orders-list-item orders-list-orders">{`${partOrder.branch}-${partOrder.order}`}</ul>
						<ul className="orders-list-item orders-list-company-name">
							{partOrder.client?.name}
						</ul>
						<ul className="orders-list-item orders-list-category">
							{handleOrderCategoryType(partOrder.category)}
						</ul>
						<ul className="orders-list-item orders-list-state">
							{partOrder.client?.state}
						</ul>
						<ul className="orders-list-item orders-list-issue-date">
							{partOrder.issue_date
								? moment(partOrder.issue_date).format('DD/MM/YY')
								: '-'}
						</ul>
						<ul className="orders-list-item orders-list-delivery-date">
							{partOrder.delivery_date
								? moment(partOrder.delivery_date).format('DD/MM/YY')
								: '-'}
						</ul>
						<ul className="orders-list-item orders-list-salesperson">
							{partOrder.salesperson?.code
								? handleSalespersonType(partOrder.salesperson?.code)
								: '-'}
						</ul>
						<ul className="orders-list-item orders-list-status">
							{handleOrderStatus(partOrder)}
						</ul>
						<ul className="orders-list-item orders-list-follow-up">
							{partOrder.prp_follow_up
								? moment(partOrder.prp_follow_up).format('DD/MM/YY')
								: '-'}
						</ul>
						<ul className="orders-list-item orders-list-more-options"></ul>
					</NavLink>
				)
			})
		) : (
			<EmptyList text={'Nenhum pedido encontrado'} />
		)
	}

	return (
		<div className="orders-list">
			{isLoading ? <LoadingList /> : listContent()}
		</div>
	)
}
export default OrdersListBody
