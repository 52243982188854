import { Button, Search } from '@praticabr/ppsa'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import React, { useContext } from 'react'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import './styles.scss'

interface Props {
	onActionClick: () => void
}

const TransfersHeader: React.FC<Props> = ({ onActionClick }) => {
	const { onSearchEquip } = useContext(TransfersContextElement)

	return (
		<section className="transfers-header-content">
			<div className="options-list-area">
				<div id="joyride-transfer-searchbar">
					<Search.root>
						<Search.icon>
							<SearchIcon height="100%" width="100%" />
						</Search.icon>
						<Search.input
							placeholder="Procurar equipamento"
							onChangeValues={onSearchEquip}
						/>
					</Search.root>
				</div>
				<Button
					id="joyride-newtransfer-button"
					size="lg"
					title="NOVA TRANSFERÊNCIA"
					variant="confirmation-solid"
					onClick={onActionClick}
				/>
			</div>
		</section>
	)
}

export default TransfersHeader
