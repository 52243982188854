import { Section } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import OrderMenuTabs from 'module/orderManagement/components/OrderMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import React, { useContext, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ViewType } from 'utils/Constants'
import AllBalanceStorageListBody from './AllBalanceStorageListBody'
import AllBalanceStorageListHeader from './AllBalanceStorageListHeader'
import './styles.scss'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
}

const OrderStorageTab: React.FC<TParams> = ({ match, ...rest }) => {
	const { order, onOrderChange } = useContext(OrderDetailsContextElement)
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const { viewType } = useContext(DeviceContextElement)
	const { orderCode, branch } = match.params

	useEffect(() => {
		if (order?.code.trim() !== orderCode.trim()) {
			onOrderChange(orderCode, branch)
		}
	}, [orderCode])

	const mainContain = () => {
		return (
			<div className="all-balance-storage-tab">
				<Section.root>
					<Section.title>Itens a faturar</Section.title>
					<Section.container>
						<div className="all-balance-storage-table">
							{viewType !== ViewType.MOBILE && (
								<AllBalanceStorageListHeader billed={false} />
							)}
							<AllBalanceStorageListBody
								match={match}
								{...rest}
								billed={false}
							/>
						</div>
					</Section.container>
				</Section.root>
				<Section.root>
					<Section.title>Itens faturados</Section.title>
					<Section.container>
						<div className="all-balance-storage-table">
							{viewType !== ViewType.MOBILE && (
								<AllBalanceStorageListHeader billed={true} />
							)}
							<AllBalanceStorageListBody
								match={match}
								{...rest}
								billed={true}
							/>
						</div>
					</Section.container>
				</Section.root>
			</div>
		)
	}

	return order && sa1010 ? (
		<>
			<OrderMenuTabs path={match.path} />
			<TabTitles />
			{mainContain()}
		</>
	) : (
		<></>
	)
}
export default OrderStorageTab
