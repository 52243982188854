import DateFnsUtils from '@date-io/date-fns'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
	Button,
	CheckBox,
	LoadingList,
	Modal,
	MoreOptions,
	Select
} from '@praticabr/ppsa'
import CheckIcon from 'assets/image/check-icon.svg'
import brLocale from 'date-fns/locale/pt-BR'
import { MoreOptionsItem } from 'models/MoreOptionsItem'
import { RequisitionError } from 'models/RequisitionError'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import Pendency from 'module/orderManagement/models/Pendency'
import {
	IPendencyDeleteCloudRequest,
	IPendencyUpdateCloudRequest
} from 'module/orderManagement/models/cloudTotvsRequisitionModels/IPendencyCloudRequisition'
import {
	PendencyOriginTypes,
	PendencyStatusTypes,
	PendencyTypes,
	ResponsibleSolutionTypes,
	handlePendencyOriginType,
	handlePendencyStatusType,
	handlePendencyTypes,
	handleResponsibleSolutionType
} from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { store } from 'store'
import { removeAccents } from 'utils/Pipe'
import HistoryPendency from '../../HistoryPendency'
import './styles.scss'

type TParams = {
	pendencies: Pendency[]
	onSelectPendency: (checkedPendency: any[]) => void
}

const AllPendencyListBodyMobile = ({
	pendencies,
	onSelectPendency
}: TParams) => {
	const { orderIsLoading, onUpdateZZF010, onDeleteZZF010 } = useContext(
		OrderDetailsContextElement
	)
	const [isEditing, setIsEditing] = useState<{ status: boolean; id: number }>({
		status: false,
		id: 0
	})
	const [showDeletePendencyConfirmModal, setShowDeletePendencyConfirmModal] =
		useState<boolean>(false)
	const [
		showHistoricPendencyConfirmModal,
		setShowHistoricPendencyConfirmModal
	] = useState<boolean>(false)
	const [responsibleSolution, setResponsibleSolution] = useState<string[]>([])
	const [issueResolver, setIssueResolver] = useState<string[]>([])
	const [checkedPendency, setCheckedPendency] = useState<Pendency[]>([])
	const [pendencyStatus, setPendencyStatus] = useState<string[]>([])
	const [pendencyOrigin, setPendencyOrigin] = useState<string[]>([])
	const [pendencyRecno, setPendencyRecno] = useState<string[]>([])
	const [pendencyType, setPendencyType] = useState<string[]>([])
	const [expectedDate, setExpectedDate] = useState<MaterialUiPickersDate>()
	const [pendencyHistory, setPendencyHistory] = useState<any[]>([])
	const { name, surname } = store.getState().user

	useEffect(() => {
		onSelectPendency(checkedPendency)
	}, [checkedPendency])

	const moreOptionsContent = (item: any) => {
		const optionEdit: MoreOptionsItem = {
			label: 'Editar',
			onClick: () => {
				setPendencyOrigin([item.pendency_origin])
				setPendencyType([item.pendency_type])
				setPendencyStatus([item.pendency_status])
				setResponsibleSolution([item.responsible_solution])
				setIssueResolver([item.issue_resolver])
				setExpectedDate(handleTransformDateProtheus(item.expected_date))
				setIsEditing({ id: item.pendency_recno, status: true })
			}
		}
		const optionDelete: MoreOptionsItem = {
			label: 'Excluir',
			onClick: async () => {
				setPendencyRecno([String(item.pendency_recno)])
				setShowDeletePendencyConfirmModal(true)
			}
		}
		const optionHistoric: MoreOptionsItem = {
			label: 'Histórico',
			onClick: () => {
				setShowHistoricPendencyConfirmModal(true)
				setPendencyHistory(item)
			}
		}

		const moreOptions = [optionEdit, optionDelete, optionHistoric]
		return (
			<div className="options">
				<MoreOptions
					id="service-order-list-item-options"
					options={moreOptions}
				/>
			</div>
		)
	}

	const handleUpdatePendency = (pendency_recno: string) => {
		const updatePendencyPayload: IPendencyUpdateCloudRequest = {
			pendency_recno: pendency_recno,
			user: removeAccents(`${name} ${surname}`)
		}

		const onCompletedUpdate = (hasError?: RequisitionError) => {
			if (hasError) {
				toast.error('Erro ao atualizar dados da pendencia!')
			} else {
				setIsEditing({ id: 0, status: false })
				toast.success('Pendência atualizada com sucesso!')
			}
		}

		if (responsibleSolution[0]) {
			updatePendencyPayload.responsible_solution = responsibleSolution[0]
		}
		if (issueResolver[0]) {
			updatePendencyPayload.issue_resolver = issueResolver[0]
		}
		if (pendencyStatus[0]) {
			updatePendencyPayload.pendency_status = pendencyStatus[0]
		}
		if (pendencyOrigin[0]) {
			updatePendencyPayload.pendency_origin = pendencyOrigin[0]
		}
		if (pendencyType[0]) {
			updatePendencyPayload.pendency_type = pendencyType[0]
		}
		if (expectedDate) {
			updatePendencyPayload.expected_date = String(expectedDate)
		}

		if (updatePendencyPayload.pendency_recno) {
			onUpdateZZF010(updatePendencyPayload, onCompletedUpdate)
		}
	}

	function handleCheckPendency(pendency: Pendency) {
		const currentIndex = checkedPendency.indexOf(pendency)
		const newChecked = [...checkedPendency]

		if (currentIndex === -1) {
			newChecked.push(pendency)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setCheckedPendency(newChecked)
	}

	const handleTransformDateProtheus = (date: string) => {
		return new Date(
			Number(date.substring(0, 4)),
			Number(date.substring(4, 6)) - 1,
			Number(date.substring(6, 8))
		)
	}

	const deleteModalContain = () => {
		const updatePendencyPayload: IPendencyDeleteCloudRequest = {
			pendency_recno: pendencyRecno[0],
			user: `${name} ${surname}`
		}

		const onCompletedDelete = (hasError?: RequisitionError) => {
			if (hasError) {
				toast.error('Erro ao deletar dados da pendencia!')
			} else {
				setShowDeletePendencyConfirmModal(false)
				toast.success('Pendência deletada com sucesso!')
			}
		}

		return (
			<div className="delete-pendency-contain">
				<div className="delete-pendency-contain-texts">
					<span>Deseja confirmar a exclusão?</span>
					<span>Esta ação não poderá ser desfeita.</span>
				</div>
				<div className="delete-pendency-contain-actions">
					<Button
						size="lg"
						title="SIM"
						variant="confirmation-solid"
						onClick={() =>
							onDeleteZZF010(updatePendencyPayload, onCompletedDelete)
						}
					/>
					<Button
						size="lg"
						title="NÃO"
						variant="cancellation"
						onClick={() => setShowDeletePendencyConfirmModal(false)}
					/>
				</div>
			</div>
		)
	}

	const isEditingPending = (pendency: Pendency) => {
		return (
			<>
				<ul className="all-pendency-list-item all-pendency-list-expected-date">
					<label>Data Prevista: </label>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
						<KeyboardDatePicker
							cancelLabel="CANCELAR"
							clearLabel="LIMPAR"
							okLabel="OK"
							emptyLabel="DD/MM/AAAA"
							format="dd/MM/yyyy"
							id="expected-date-picker"
							invalidDateMessage="Data invalida"
							value={expectedDate}
							onChange={(value: MaterialUiPickersDate) => {
								setExpectedDate(value)
							}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
								className: 'period-filter-keyboard-button'
							}}
						/>
					</MuiPickersUtilsProvider>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-origin">
					<label>Origem:</label>
					<Select
						id="list-pendency_origin"
						options={PendencyOriginTypes}
						onSelectOptions={setPendencyOrigin}
						selectedOption={pendencyOrigin}
					/>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-pendency-type">
					<label>Tipo de pendência:</label>
					<Select
						id="list-pendency_type"
						options={PendencyTypes}
						onSelectOptions={setPendencyType}
						selectedOption={pendencyType}
					/>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-resp-solution">
					<label>Setor Solução:</label>
					<Select
						id="list-responsible_solution"
						options={ResponsibleSolutionTypes}
						selectedOption={responsibleSolution}
						onSelectOptions={setResponsibleSolution}
					/>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-resp-solution">
					<label>Resp. Solução:</label>
					<Select
						id="list-responsible_solution"
						options={ResponsibleSolutionTypes}
						selectedOption={issueResolver}
						onSelectOptions={setIssueResolver}
					/>
				</ul>

				<ul className="all-pendency-list-item all-pendency-list-status">
					<label>Status: </label>
					<Select
						id="list-pendency_status"
						options={PendencyStatusTypes}
						selectedOption={pendencyStatus}
						onSelectOptions={setPendencyStatus}
					/>
				</ul>
			</>
		)
	}

	const pendingContent = (pendency: Pendency) => {
		return (
			<>
				<ul className="all-pendency-list-item all-pendency-list-expected-date">
					<label>Data Prevista: </label>
					<span>
						{pendency.expected_date
							? moment(pendency.expected_date).format('DD/MM/YY')
							: '-'}
					</span>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-origin">
					<label>Origem:</label>
					<span>
						{pendency.pendency_origin
							? handlePendencyOriginType(pendency.pendency_origin)
							: '-'}
					</span>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-pendency-type">
					<label>Tipo de pendência:</label>
					<span title={handlePendencyTypes(pendency.pendency_type)}>
						{pendency.pendency_type
							? handlePendencyTypes(pendency.pendency_type)
							: '-'}
					</span>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-resp-solution">
					<label>Setor Solução:</label>
					<span
						title={handleResponsibleSolutionType(pendency.responsible_solution)}
					>
						{pendency.responsible_solution
							? handleResponsibleSolutionType(pendency.responsible_solution)
							: '-'}
					</span>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-resp-solution">
					<label>Resp. Solução:</label>
					<span title={pendency.issue_resolver}>
						{pendency.issue_resolver ? pendency.issue_resolver : '-'}
					</span>
				</ul>
				<ul className="all-pendency-list-item all-pendency-list-status">
					<label>Status: </label>
					<span>
						{pendency.pendency_status
							? handlePendencyStatusType(pendency.pendency_status)
							: '-'}
					</span>
				</ul>
			</>
		)
	}

	const listContent = (pendencyItensList: Pendency[]) => {
		return pendencyItensList.map((pendency: Pendency) => {
			return (
				<div
					className="all-pendency-list-body-mobile"
					key={pendency.pendency_recno}
				>
					<ul className="all-pendency-list-item all-pendency-list-pendency">
						<label htmlFor="">Pendência:</label>
						<label
							style={{ lineHeight: '38px', paddingLeft: '10px' }}
							htmlFor={String(pendency.pendency_recno)}
						>
							<CheckBox
								onChange={(e) => handleCheckPendency(pendency)}
								variant="blue"
								checked={!!checkedPendency.find((e) => e == pendency)}
								format="square"
								id={String(pendency.pendency_recno)}
								name={pendency.pendency_description}
							/>
							{orderIsLoading ? (
								<Skeleton />
							) : (
								<span>
									{pendency.pendency_description
										? pendency.pendency_description
										: '-'}
								</span>
							)}
						</label>
					</ul>
					<ul className="all-pendency-list-item all-pendency-list-inclusion-date">
						{orderIsLoading ? (
							<Skeleton />
						) : (
							<>
								<label>Data de inclusão:</label>
								{isEditing.status &&
								isEditing.id === pendency.pendency_recno ? (
									<span style={{ lineHeight: '38px', paddingLeft: '10px' }}>
										{pendency.inclusion_date
											? moment(pendency.inclusion_date).format('DD/MM/YY')
											: '-'}
									</span>
								) : (
									<span>
										{pendency.inclusion_date
											? moment(pendency.inclusion_date).format('DD/MM/YY')
											: '-'}
									</span>
								)}
							</>
						)}
					</ul>
					{isEditing.status && isEditing.id === pendency.pendency_recno
						? isEditingPending(pendency)
						: pendingContent(pendency)}
					<ul className="all-pendency-list-item-actions">
						{isEditing.status && isEditing.id === pendency.pendency_recno ? (
							<img
								src={CheckIcon}
								alt="confirm changes pendency icon"
								onClick={() => {
									handleUpdatePendency(String(pendency.pendency_recno))
								}}
							/>
						) : (
							<></>
						)}
					</ul>

					<ul className="all-pendency-list-item-options">
						{!isEditing.status && !isEditing.id ? (
							<div>{moreOptionsContent(pendency)}</div>
						) : (
							<></>
						)}
					</ul>
				</div>
			)
		})
	}

	return (
		<>
			{orderIsLoading ? <LoadingList /> : listContent(pendencies)}
			{showDeletePendencyConfirmModal && (
				<Modal.root>
					<Modal.header
						title="EXCLUIR PENDÊNCIAS"
						onClose={() => setShowDeletePendencyConfirmModal(false)}
					/>
					<Modal.body>
						<Modal.body.main>{deleteModalContain()}</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)}
			{showHistoricPendencyConfirmModal && (
				<Modal.root>
					<Modal.header
						title="HISTÓRICO DA PENDÊNCIA"
						onClose={() => {
							setShowHistoricPendencyConfirmModal(false)
							setPendencyHistory([])
						}}
					/>
					<Modal.body>
						<Modal.body.main>
							<HistoryPendency pendency={pendencyHistory} />
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)}
		</>
	)
}

export default AllPendencyListBodyMobile
