import { Button, Input, LoadingList } from '@praticabr/ppsa'
import { RequisitionError } from 'models/RequisitionError'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { IPendencyUpdateCloudRequest } from 'module/orderManagement/models/cloudTotvsRequisitionModels/IPendencyCloudRequisition'
import { handleResponsibleSolutionType } from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { store } from 'store'
import { removeAccents } from 'utils/Pipe'
import './styles.scss'

type HistoricPendencyProps = {
	pendency: any
}

interface IPendency {
	observation: string
}

interface Objeto {
	data: string
	user: string
	description: string
}

const HistoryPendency: React.FC<HistoricPendencyProps> = ({
	pendency
}: HistoricPendencyProps) => {
	const { onUpdateZZF010 } = useContext(OrderDetailsContextElement)

	const { name, surname } = store.getState().user

	const [addObservation, setAddObservation] = useState(false)
	const [historyPendencyList, setHistoryPendencyList] = useState<Objeto[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		resetField
	} = useForm<IPendency>()

	function formatDate(date: Date): string {
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Mês começa em 0
		const year = String(date.getFullYear()).slice(-2)
		const hours = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')
		const seconds = String(date.getSeconds()).padStart(2, '0')

		return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`
	}

	const handleUpdatePendency = (data: any) => {
		setIsLoading(true)
		const updatePendencyPayload: IPendencyUpdateCloudRequest = {
			pendency_recno: pendency.pendency_recno,
			user: removeAccents(`${name} ${surname}`),
			expected_date: pendency.expected_date,
			issue_resolver: pendency.issue_resolver,
			pendency_origin: pendency.pendency_origin,
			pendency_status: pendency.pendency_status,
			pendency_type: pendency.pendency_type,
			responsible_solution: pendency.responsible_solution
		}

		if (data.observation) {
			updatePendencyPayload.pendency_observation = removeAccents(
				data.observation
			)
		}

		const onCompletedUpdate = (hasError?: RequisitionError) => {
			if (hasError) {
				toast.error('Erro ao atualizar dados da pendencia!')
				setIsLoading(false)
			} else {
				toast.success('Pendência atualizada com sucesso!')
				const newObject: Objeto = {
					data: formatDate(new Date()),
					user: removeAccents(`${name} ${surname}`).toUpperCase(),
					description: removeAccents(data.observation)
				}
				historyPendencyList.push(newObject)
				setAddObservation(false)
				setIsLoading(false)
				resetField('observation')
			}
		}

		if (updatePendencyPayload.pendency_recno) {
			onUpdateZZF010(updatePendencyPayload, onCompletedUpdate)
		}
	}

	const parseObservations = (text: string): Objeto[] => {
		let objetos = [] as Objeto[]
		if (!text) {
			return objetos
		}

		const regex =
			/DATA: (\d{1,2}\/\d{1,2}\/\d{2,4}) - (\d{2}:\d{2}:\d{2})\s*USUARIO: ([^\n]+)\s*DESCRICAO:([\s\S]*?)(?=\n-+)/gm

		const matches = [...text.matchAll(regex)]

		objetos = matches.map((match) => {
			return {
				data: `${match[1]} - ${match[2]}`,
				user: match[3],
				description: match[4]
			}
		})
		return objetos
	}

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>
	): void => {
		if (event.key === 'Enter') {
			handleSubmit(handleUpdatePendency)()
		}
	}

	function normalizeAllUpperCase(text: string) {
		return text.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
	}

	useEffect(() => {
		setHistoryPendencyList(parseObservations(pendency.pendency_history))
	}, [])

	return (
		<div className="history-pendency-list">
			<div className="history-pendency-header">
				<label>{normalizeAllUpperCase(pendency.pendency_description)}</label>
				<div>
					<span>
						<strong>Resp. Solução: </strong>{' '}
						{handleResponsibleSolutionType(pendency.responsible_solution)}
					</span>
					<span>
						<strong> Data Prevista: </strong>
						{moment(pendency.expected_date).format('DD/MM/YY')}
					</span>
				</div>
			</div>
			{isLoading ? (
				<LoadingList />
			) : (
				<>
					<div className="history-pendency-body">
						<>
							{historyPendencyList.map((item: Objeto, index: number) => {
								return (
									<div className="timeline-pendency" key={index}>
										<div className={`service-indicators`}>
											<div
												className={`circle-indicator ${
													index === 0 ? 'first' : ''
												} `}
											></div>
											<div
												className={`line-indicator ${
													index === 0 ? 'first' : ''
												} ${index == 0 ? 'first-last' : ''}
												${index !== 0 ? 'last' : ''}`}
											></div>
											<div className={`dots-indicator last`}></div>
										</div>
										<div className="timeline-pendency-template"></div>
										<div className="obs-history-timeline" key={index}>
											<div>
												{item.data}
												<label>{item.user}:</label>
											</div>
											<p>{normalizeAllUpperCase(item.description)}</p>
										</div>
									</div>
								)
							})}
						</>
					</div>
					{addObservation && (
						<div className="history-pendency-add-observation">
							<>
								<label htmlFor="observation">Fazer observação</label>
								<Input
									variant="light"
									id="observation"
									onKeyDown={(e) => handleKeyDown(e)}
									placeholder="Digite aqui sua obervação sobre a pendencia"
									{...register('observation', {
										required: { value: true, message: 'Campo obrigatório' }
									})}
									errors={errors.observation}
								/>
							</>
						</div>
					)}
					<div className="history-pendency-action">
						{addObservation ? (
							<>
								<Button
									variant="confirmation-solid"
									size="lg"
									title="SALVAR"
									onClick={handleSubmit(handleUpdatePendency)}
								/>
								<Button
									variant="cancellation"
									size="lg"
									title="CANCELAR"
									onClick={() => {
										setAddObservation(false)
										resetField('observation')
									}}
								/>
							</>
						) : (
							<Button
								variant="outline"
								id="add-observation"
								title="ADICIONAR OBSERVAÇÃO"
								size="lg"
								onClick={() => setAddObservation(true)}
							/>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default HistoryPendency
