import {
	Button,
	GenericTable,
	handleDownloadToExcelFile,
	handleProtheusDateToDate,
	LoadingList,
	Search
} from '@praticabr/ppsa'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { handleOrderBranchTypes } from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'

export function OpenTitlesList() {
	const {
		financePosition,
		groupFinancePosition,
		financePositionLoading,
		groupFinancePositionLoading,
		sa1010
	} = useContext(ClientDetailsContextElement)
	const [openTitles, setOpenTitles] = useState<any[]>([])

	useEffect(() => {
		if (groupFinancePosition) {
			return setOpenTitles(groupFinancePosition.se1010)
		} else if (!!financePosition && !groupFinancePosition) {
			return setOpenTitles(financePosition?.se1010)
		}
	}, [financePosition, groupFinancePosition])

	function exportToExcel() {
		const data = openTitles?.map((se1: any) => ({
			Filial: se1?.e1Filial,
			Cliente: `${se1?.e1Cliente} - ${se1?.e1Loja}`,
			'Nota Fiscal': `${se1?.e1Num} - ${se1?.e1Prefixo}`,
			Parcela: se1?.e1Parcela,
			Tipo: se1?.e1Tipo,
			Emissão: handleProtheusDateToDate(se1?.e1Emissao),
			Vencimento: handleProtheusDateToDate(se1?.e1Vencto),
			Valor: Number(se1?.e1Valor),
			Saldo: Number(se1?.e1Saldo)
		}))

		handleDownloadToExcelFile(
			data,
			`Titulos Abertos - ${sa1010?.a1Cod}-${sa1010?.a1Loja}-${sa1010?.a1Nome}.xlsx`
		)
	}

	function handleFilterResults(value: string | number) {
		if (groupFinancePosition) {
			setOpenTitles(
				groupFinancePosition.se1010.filter(
					(se1: any) =>
						se1?.e1Cliente.includes(value) ||
						se1?.e1Loja.includes(value) ||
						se1?.e1Num.includes(value) ||
						se1?.e1Tipo.includes(value)
				)
			)
		} else if (financePosition) {
			setOpenTitles(
				financePosition?.se1010.filter(
					(se1: any) =>
						se1?.e1Cliente.includes(value) ||
						se1?.e1Loja.includes(value) ||
						se1?.e1Num.includes(value) ||
						se1?.e1Tipo.includes(value)
				)
			)
		}
	}

	return (
		<div className="client-finance-details-tab">
			<div className="client-finance-details-tab-header-actions">
				<Search.root>
					<Search.icon>
						<SearchIcon height="100%" width="100%" />
					</Search.icon>
					<Search.input
						onChangeValues={(e) => handleFilterResults(e)}
						placeholder="Buscar por cliente, loja ou nota fiscal..."
					/>
				</Search.root>
				<Button
					size="lg"
					title="Exportar"
					onClick={exportToExcel}
					variant="confirmation-solid"
					disabled={!openTitles?.length}
				/>
			</div>
			<div className="open-titles-table">
				{financePositionLoading || groupFinancePositionLoading ? (
					<LoadingList />
				) : (
					<GenericTable
						data={[
							...(openTitles?.map((se1: any) => ({
								...se1,
								id: se1?.rECNO,
								branch: handleOrderBranchTypes(se1?.e1Filial),
								client: `${se1?.e1Cliente} - ${se1?.e1Loja}`,
								invoice: `${se1?.e1Num} - ${se1?.e1Prefixo}`,
								installment: se1?.e1Parcela,
								type: se1?.e1Tipo,
								issueDate: handleProtheusDateToDate(
									se1?.e1Emissao
								)?.toLocaleDateString(),
								dueDate: handleProtheusDateToDate(
									se1?.e1Vencto
								)?.toLocaleDateString(),
								value: Number(se1?.e1Valor).toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL'
								}),
								balance: Number(se1?.e1Saldo).toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL'
								})
							})) ?? [])
						]}
						columns={[
							{
								key: 'branch',
								header: 'Filial',
								width: '10%'
							},
							{
								key: 'client',
								header: 'Cliente',
								width: '10%'
							},
							{
								key: 'invoice',
								header: 'Nota fiscal',
								width: '12%'
							},
							{
								key: 'installment',
								header: 'Parcela',
								width: '10%'
							},
							{
								key: 'type',
								header: 'Tipo',
								width: '10%'
							},
							{
								key: 'issueDate',
								header: 'Emissão',
								width: '12%'
							},
							{
								key: 'dueDate',
								header: 'Vencimento',
								width: '12%'
							},
							{
								key: 'value',
								header: 'Valor',
								width: '12%'
							},
							{
								key: 'balance',
								header: 'Saldo',
								width: '12%'
							}
						]}
					/>
				)}
			</div>
		</div>
	)
}
