import { Section } from '@praticabr/ppsa'
import React, { ReactNode } from 'react'
import './styles.scss'

export const PaymentDetailsRoot = ({ children }: { children: ReactNode }) => (
	<Section.root>
		<Section.title>Pagamento</Section.title>
		<Section.container>
			<div className="new-payment-section">{children}</div>
		</Section.container>
	</Section.root>
)
