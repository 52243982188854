import { EmptyList, LoadingList } from '@praticabr/ppsa'
import financialBlockIcon from 'assets/image/financial-block.svg'
import financialNotBlockIcon from 'assets/image/financial-not-block.svg'
import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import ClientListItem from 'module/orderManagement/models/ClientListItem'
import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './styles.scss'

const ClientsListBody: React.FC = () => {
	const { clientList, isLoading, totalPages } = useContext(ClientContextElement)
	const { onSelectClient } = useContext(ClientDetailsContextElement)

	const handleStatus = (financial_status: 'N' | 'S') => {
		return financial_status === 'S' ? (
			<img
				src={financialBlockIcon}
				alt="financial block icon"
				style={{ height: '26px', width: '16px' }}
			/>
		) : (
			<img
				src={financialNotBlockIcon}
				alt="financial not block icon"
				style={{ height: '26px', width: '16px' }}
			/>
		)
	}

	const listContent = () => {
		return totalPages ? (
			clientList.map((client: ClientListItem, index) => {
				return (
					<NavLink
						className="clients-list-row"
						to={`/client/${client.client_code}/${client.client_store_code}`}
						id={client.client_code}
						key={index}
						onClick={() =>
							onSelectClient({
								a1Cod: client.client_code,
								a1Loja: client.client_store_code
							})
						}
					>
						<div className="clients-list-item">
							<span className="info-title">Razão social:</span>
							<span className="info-value">{client.company_name}</span>
						</div>
						<div className="clients-list-item">
							<span className="info-title">CPF/CNPJ:</span>
							<span className="info-value">
								{client.company_register ? client.company_register : '-'}
							</span>
						</div>
						<div className="clients-list-first-line">
							<div>
								<span className="info-title">Código:</span>
								<span className="info-value">{client.client_code}</span>
							</div>
							<div>
								<span className="info-title">Bloqueio financeiro:</span>
								<span className="info-value">
									{handleStatus(client.financial_block_status)}
								</span>
							</div>
						</div>
						<div className="clients-list-item">
							<span className="info-title">Vendedor:</span>
							<span className="info-value">{client.salesperson}</span>
						</div>
						<div className="clients-list-item">
							<span className="info-title">Ult. pedido:</span>
							<span className="info-value">
								{client.last_order_code
									? `${client.last_order_branch}-${client.last_order_code}`
									: ''}
							</span>
						</div>
						<div className="clients-list-item">
							<span className="info-title">Emissão:</span>
							<span className="info-value">
								{client.last_order_issue_date
									? moment(client.last_order_issue_date).format('DD/MM/YY')
									: ''}
							</span>
						</div>
					</NavLink>
				)
			})
		) : (
			<EmptyList text="Nenhum cliente encontrado" />
		)
	}

	return (
		<>
			{isLoading ? (
				<LoadingList />
			) : (
				<div className="clients-list-mobile">{listContent()}</div>
			)}
		</>
	)
}
export default ClientsListBody
