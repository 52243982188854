import {
	Button,
	EmptyList,
	GenericTable,
	handleType,
	LoadingProgress,
	Modal,
	MoreOptions,
	Search
} from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { AttachmentCategoryModal } from 'module/orderManagement/components/AttachmentCategoryModal'
import { fileAttachmentCategories } from 'module/orderManagement/components/UploadModal'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { PrpOrder } from 'module/orderManagement/models/PrpOrderModel'
import {
	ClientInsertAttachment,
	orderAttachmentDelete,
	orderAttachmentsImport
} from 'module/orderManagement/services'
import React, { useContext, useEffect, useState } from 'react'
import { MdVisibility } from 'react-icons/md'
import { toast } from 'react-toastify'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { store } from 'store'
import { ViewType } from 'utils/Constants'
import './styles.scss'

type AttachmentsGuideProps = {
	prpOrder: PrpOrder
}

const AttachmentsGuide = ({ prpOrder }: AttachmentsGuideProps) => {
	const { viewType } = useContext(DeviceContextElement)
	const { onUpdateOrderPrp, orderPrpIsLoading } = useContext(
		OrderDetailsContextElement
	)
	const { clientPrp } = useContext(ClientDetailsContextElement)
	const { profile } = store.getState().user
	const [showImportAttachmentModal, setShowImportAttachmentModal] =
		useState<boolean>(false)
	const [showAttachmentCategoryModal, setShowAttachmentCategoryModal] =
		useState<boolean>(false)
	const [selectedAttachment, setSelectedAttachment] = useState<any>()
	const [filteredAttachments, setFilteredAttachments] = useState<any[]>([])
	const [selectedItens, setSelectedEquipments] = useState<any[]>([])
	const [attachments, setAttachments] = useState<any[]>([])

	const newAttachments: {
		id: number
		date: string
		attachmentId: number
		attachmentName: string
		type: string
		link: string
		deletedAt: Date | null
		userId: number
		attachmentCategory: string | null
	}[] = []

	useEffect(() => {
		prpOrder.events.map((event) => ({
			...event.attachments.map((attachment) => {
				newAttachments.push({
					id: attachment?.file?.id,
					date: new Date(event?.createdAt).toLocaleDateString(),
					attachmentId: attachment?.id ?? 0,
					attachmentName: attachment?.file?.name,
					type: attachment?.file?.file_type?.toUpperCase()?.split('/')[1],
					link: attachment?.file?.link,
					deletedAt: attachment?.deletedAt,
					userId: event.userId,
					attachmentCategory: attachment?.file?.attachment_category
				})
			})
		}))

		setFilteredAttachments(
			newAttachments.filter(
				(formattedEvent) =>
					formattedEvent.id !== undefined && !formattedEvent.deletedAt
			)
		)
		setAttachments(
			newAttachments.filter(
				(formattedEvent) =>
					formattedEvent.id !== undefined && !formattedEvent.deletedAt
			)
		)
	}, [prpOrder])

	const openAttachment = (e: any) => {
		window.open(e.link, '_blank')
	}

	const handleDelete = async (id: number) => {
		const response = await orderAttachmentDelete(id)
		onUpdateOrderPrp(prpOrder.protheusOrder)
		if (response?.data?.statusCode === 200) {
			toast.success('Anexo deletado com sucesso.')
		} else {
			toast.error('Erro ao deletar anexo.')
		}
	}

	const handleSelectItems = (item: any) => {
		delete item.action
		const currentIndex = selectedItens.findIndex(
			(equipment) => equipment.id === item?.id && equipment?.code === item?.code
		)

		const newChecked = [...selectedItens]

		if (currentIndex === -1) {
			newChecked.push(item)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setSelectedEquipments(newChecked)
	}

	const handleImportAttachments = async () => {
		if (!prpOrder?.id || selectedItens?.length === 0) return

		const data = {
			content: 'Anexo importado do cliente',
			createdAt: new Date(),
			followUpAt: null,
			orderId: prpOrder?.id,
			type: 'attachment',
			userId: profile,
			attachments: selectedItens
		}

		const response = await orderAttachmentsImport(data)
		onUpdateOrderPrp(prpOrder.protheusOrder)
		if (response.status === 200) {
			toast.success('Anexo importado com sucesso!')
			setShowImportAttachmentModal(false)
		} else {
			toast.error('Erro ao importar anexo!')
			setShowImportAttachmentModal(false)
		}
	}

	const handleExportClientAttachment = (id: number) => {
		const attachments: any[] = []
		prpOrder.events.map((event) => {
			if (event.attachments?.length > 0) {
				attachments.push(...event.attachments)
			}
		})
		const filteredAttachment = attachments.filter(
			(attachment) => attachment.id === id
		)

		const data = {
			description: filteredAttachment[0].description,
			clientId: clientPrp?.id,
			role: '',
			file: filteredAttachment[0].file,
			deletedAt: null
		}

		ClientInsertAttachment([data])
			.then(() => {
				onUpdateOrderPrp(prpOrder.protheusOrder)
				toast.success('Arquivo exportado para o cliente com sucesso!')
			})
			.catch(() => toast.error('Erro ao exportar arquivo!'))
	}

	const searchAttachments = (value: string) => {
		if (!filteredAttachments) return
		if (value) setAttachments(filteredAttachments)
		const filtered = filteredAttachments.filter(
			(attachment) =>
				attachment?.attachmentName
					?.toLowerCase()
					.includes(value?.toLowerCase()) ||
				attachment?.type?.toLowerCase().includes(value?.toLowerCase()) ||
				attachment?.date?.toLowerCase().includes(value?.toLowerCase())
		)
		setAttachments(filtered)
		return attachments
	}

	const handleEditCategory = (attachment: any) => {
		setShowAttachmentCategoryModal(true)
		setSelectedAttachment(attachment)
	}

	const optionsOnMoreOptions = (attachment: any) => {
		if (attachment?.userId === profile) {
			return [
				{
					label: 'Editar',
					onClick: () => handleEditCategory(attachment)
				},
				{
					label: 'Exportar para o cliente',
					onClick: () => handleExportClientAttachment(attachment.attachmentId)
				},
				{
					label: 'Excluir',
					onClick: () => handleDelete(attachment.id)
				}
			].filter((item) =>
				!attachment.link ? item.label !== 'Exportar para o cliente' : item
			)
		}

		return [
			{
				label: 'Exportar para o cliente',
				onClick: () => handleExportClientAttachment(attachment.attachmentId)
			},
			{
				label: 'Excluir',
				onClick: () => handleDelete(attachment.id)
			}
		]?.filter((item) =>
			!attachment.link ? item.label !== 'Exportar para o cliente' : item
		)
	}

	return (
		<>
			<div className="order-follow-up-tab-attachments-guide">
				<div className="order-follow-up-tab-attachments-guide-action">
					<div className="order-follow-up-tab-attachments-guide-search">
						<Search.root>
							<Search.icon>
								<SearchIcon height="100%" width="100%" />
							</Search.icon>
							<Search.input
								placeholder="Procurar Arquivo"
								onChangeValues={(value) => searchAttachments(value)}
							/>
						</Search.root>
					</div>

					<Button
						title="IMPORTAR ANEXO"
						variant="confirmation-solid"
						size="lg"
						onClick={() => setShowImportAttachmentModal(true)}
					/>
				</div>
				<div className="order-follow-up-tab-attachments-table-results">
					<GenericTable
						data={[
							...attachments
								.sort((a, b) => b.id - a.id)
								.map((attachment) => ({
									description: attachment.attachmentName,
									category: handleType(
										attachment.attachmentCategory,
										fileAttachmentCategories
									),
									...attachment,
									moreOptions:
										attachment?.link || attachment?.userId === profile ? (
											<div className="attachment-more-option">
												<MoreOptions
													id={`${attachment.id}`}
													options={optionsOnMoreOptions(attachment)}
												/>
											</div>
										) : null,
									disabledTable: attachment?.link ? false : true
								}))
						]}
						columns={[
							{
								key: 'description',
								header: 'Documento',
								width:
									viewType !== ViewType.MOBILE
										? 'calc(40% - 12px)'
										: 'calc(30% - 10px)'
							},
							{
								key: 'category',
								header: 'Categoria',
								width: viewType !== ViewType.MOBILE ? '20%' : '20%'
							},
							{
								key: 'date',
								header: 'Data',
								width: viewType !== ViewType.MOBILE ? '20%' : '30%'
							},
							{
								key: 'type',
								header: 'Tipo',
								width: viewType !== ViewType.MOBILE ? '20%' : '20%'
							},
							{
								key: 'moreOptions',
								header: '',
								width: '10px'
							}
						]}
						onRowClicked={openAttachment}
					/>
				</div>
			</div>
			{showImportAttachmentModal && (
				<Modal.root>
					<Modal.header
						title="IMPORTAR ANEXO"
						onClose={() => setShowImportAttachmentModal(false)}
					/>
					<Modal.body>
						<Modal.body.main>
							<div className="order-follow-up-import-attachment">
								<label>
									Selecione os anexos que deseja importar para esse pedido:
								</label>
								<div className="order-follow-up-import-attachment-table">
									{clientPrp?.attachments &&
									clientPrp?.attachments?.length > 0 ? (
										<GenericTable
											onRowClicked={handleSelectItems}
											data={clientPrp?.attachments
												.filter((attachment) => attachment.file)
												.filter((attachment) => attachment.deletedAt === null)
												.map((attachment) => ({
													id: attachment?.id,
													document: attachment?.description,
													category: handleType(
														attachment.file.attachment_category,
														fileAttachmentCategories
													),
													type: attachment?.file?.file_type
														?.toUpperCase()
														.split('/')[1],
													include: new Date(
														attachment?.createdAt
													).toLocaleDateString(),
													link: attachment?.file?.link,
													action: attachment?.file?.link && (
														<MdVisibility
															size={20}
															color="var(--primary-pure)"
															onClick={() =>
																window.open(attachment?.file?.link, '_blank')
															}
														/>
													),
													disabledTable: attachment?.file?.link ? false : true,
													...attachment
												}))}
											columns={[
												{
													key: 'checkbox',
													checkbox: true,
													selected: selectedItens,
													header: '',
													width: '10px'
												},
												{
													key: 'description',
													header: 'Documento',
													width:
														viewType !== ViewType.MOBILE
															? 'calc(30% - 10px)'
															: 'calc(25% - 20px)'
												},
												{
													key: 'category',
													header: 'Categoria',
													width: '20%'
												},
												{
													key: 'type',
													header: 'Tipo',
													width: '20%'
												},
												{
													key: 'include',
													header: 'Data',
													width: viewType !== ViewType.MOBILE ? '25%' : '30%'
												},
												{
													key: 'action',
													header: '',
													width: viewType !== ViewType.MOBILE ? '5%' : '10px'
												}
											]}
										/>
									) : (
										<EmptyList text="Nenhum anexo encontrado" />
									)}
								</div>
							</div>
						</Modal.body.main>
						<Modal.body.actions>
							<div className="order-follow-up-import-attachment-action">
								<Button
									variant="confirmation-solid"
									size="lg"
									onClick={() => handleImportAttachments()}
									title="ANEXAR"
								/>
								<Button
									variant="cancellation"
									size="lg"
									onClick={() => setShowImportAttachmentModal(false)}
									title="CANCELAR"
								/>
							</div>
						</Modal.body.actions>
					</Modal.body>
				</Modal.root>
			)}
			{showAttachmentCategoryModal && (
				<AttachmentCategoryModal
					onClose={() => {
						setShowAttachmentCategoryModal(false)
						onUpdateOrderPrp(prpOrder.protheusOrder)
					}}
					file={selectedAttachment}
				></AttachmentCategoryModal>
			)}
			{orderPrpIsLoading && <LoadingProgress />}
		</>
	)
}

export default AttachmentsGuide
