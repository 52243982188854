import { Section } from '@praticabr/ppsa'
import React, { ReactNode } from 'react'
import './styles.scss'

export const ClientAccountingInformationRoot = ({
	children
}: {
	children: ReactNode
}) => (
	<Section.root>
		<Section.title>Informações contábeis</Section.title>
		<Section.container>
			<div className="new-accounting-information">{children}</div>
		</Section.container>
	</Section.root>
)
