import { Section } from '@praticabr/ppsa'
import React, { ReactNode } from 'react'
import './styles.scss'

type Props = {
	children: ReactNode
}

export const OrderItemsSectionRoot = ({ children }: Props) => (
	<Section.root>
		<Section.title>Itens do pedido</Section.title>
		<Section.container>{children}</Section.container>
	</Section.root>
)
