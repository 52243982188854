import 'assets/styles/styles.scss'
import { useClarity } from 'hooks/use-clarity'
import { useInnerHeightResize } from 'hooks/useInnerHeightResize'
import { AuthProvider } from 'module/entryPoint/context/AuthContext/AuthContext'
import DeviceContext from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import Routes from 'routes'
import { history } from 'routes/history'
import { persistor, store } from 'store'
import { UpdateNotification } from 'utils/UpdateNotification'

const root = createRoot(document.getElementById('root') as HTMLElement)

const App = () => {
	useClarity()
	useInnerHeightResize()

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<DeviceContext>
					<AuthProvider>
						<Router history={history}>
							<Routes />
							<UpdateNotification />
							<ToastContainer
								position="top-center"
								autoClose={5000}
								newestOnTop={false}
								closeOnClick={true}
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								closeButton={false}
								className="toast-main-content"
								bodyClassName="toast-body-content"
							/>
						</Router>
					</AuthProvider>
				</DeviceContext>
			</PersistGate>
		</Provider>
	)
}

root.render(<App />)
