import { EmptyList, LoadingList } from '@praticabr/ppsa'
import financialBlockIcon from 'assets/image/financial-block.svg'
import financialNotBlockIcon from 'assets/image/financial-not-block.svg'
import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import ClientListItem from 'module/orderManagement/models/ClientListItem'
import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './styles.scss'

const ClientsListBody: React.FC = () => {
	const { clientList, isLoading, totalPages } = useContext(ClientContextElement)

	const handleStatus = (financial_status: 'N' | 'S') => {
		return financial_status === 'S' ? (
			<img
				src={financialBlockIcon}
				alt="financial block icon"
				style={{ height: '26px', width: '16px' }}
			/>
		) : (
			<img
				src={financialNotBlockIcon}
				alt="financial not block icon"
				style={{ height: '26px', width: '16px' }}
			/>
		)
	}

	const listContent = () => {
		return totalPages ? (
			clientList.map((client: ClientListItem, index) => {
				return (
					<NavLink
						key={index}
						id={client.client_code}
						className="clients-list"
						to={`/client/${client.client_code}/${client.client_store_code}`}
					>
						<ul className="clients-list-item clients-list-legend">
							{handleStatus(client.financial_block_status)}
						</ul>
						<ul className="clients-list-item clients-list-client-code">
							{client.client_code}
						</ul>
						<ul className="clients-list-item clients-list-company-name">
							{client.company_name}
						</ul>
						<ul className="clients-list-item clients-list-company-register">
							{client.company_register ? client.company_register : '-'}
						</ul>
						<ul className="clients-list-item clients-list-last-order">
							{client.last_order_code
								? `${client.last_order_branch}-${client.last_order_code}`
								: ''}
						</ul>
						<ul className="clients-list-item clients-list-issue-date">
							{client.last_order_issue_date
								? moment(client.last_order_issue_date).format('DD/MM/YY')
								: ''}
						</ul>
						<ul className="clients-list-item clients-list-salesperson">
							{client.salesperson}
						</ul>
						<ul className="clients-list-item clients-list-more-options"></ul>
					</NavLink>
				)
			})
		) : (
			<EmptyList text="Nenhum cliente encontrado" />
		)
	}

	return <>{isLoading ? <LoadingList /> : listContent()}</>
}
export default ClientsListBody
