import { Search } from '@praticabr/ppsa'
import Filter from 'assets/image/Filter.svg'
import billed from 'assets/image/billed.svg'
import billed_partially from 'assets/image/billed_partially.svg'
import to_billingIcon from 'assets/image/to_billing.svg'
import { FilterData } from 'models/FilterData'
import { OrderHistoryContextElement } from 'module/orderManagement/context/OrderHistoryContext/OrderHistoryContext'
import React, { useContext, useEffect, useState } from 'react'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import HistoryFilter from './HistoryFilter'
import './styles.scss'

const OrdersHistoryActions = () => {
	const { onSearchOrder, onFilterChange } = useContext(
		OrderHistoryContextElement
	)
	const [historyFilterShowModal, setHistoryFilterShowModal] =
		useState<boolean>(false)
	const [clearParamsFilter, setClearParamsFilter] = useState<boolean>(false)

	useEffect(() => {
		setClearParamsFilter(true)
	}, [])

	const applyFilterOnLegend = (value: string[]) => {
		onFilterChange({ orderStatus: value } as FilterData)
	}

	return (
		<div className="order-history-actions">
			<div className="order-history-search-and-filter">
				<Search.root>
					<Search.icon>
						<SearchIcon width="100%" height="100%" />
					</Search.icon>
					<Search.input
						placeholder="Buscar pedido"
						onChangeValues={(e) => onSearchOrder(e)}
					/>
				</Search.root>
				<img
					className="filter-image"
					src={Filter}
					alt="filter icon"
					onClick={() => setHistoryFilterShowModal(true)}
				/>
			</div>
			<div className="order-history-legends">
				<span onClick={() => applyFilterOnLegend(['0'])}>
					<img src={to_billingIcon} style={{ height: '30px', width: '20px' }} />{' '}
					A faturar
				</span>
				<span onClick={() => applyFilterOnLegend(['6'])}>
					<img src={billed} style={{ height: '30px', width: '20px' }} />{' '}
					Faturado
				</span>
				<span onClick={() => applyFilterOnLegend(['5'])}>
					<img
						src={billed_partially}
						style={{ height: '30px', width: '20px' }}
					/>{' '}
					Faturado parcialmente
				</span>
			</div>
			{historyFilterShowModal && (
				<HistoryFilter
					onClose={() => setHistoryFilterShowModal(false)}
					clearFilter={clearParamsFilter}
				/>
			)}
		</div>
	)
}
export default OrdersHistoryActions
