import { Button, CheckBox, LoadingList, Modal, Search } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { AddOrder } from 'module/orderManagement/domain/usecases/add-order'
import { OrderItemsList } from 'module/orderManagement/models/OrderModel'
import { getItemsByValue } from 'module/orderManagement/services'
import React, { useContext, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { ViewType } from 'utils/Constants'
import './styles.scss'

interface OrderItemsSearchProps {
	fieldArray: any
	onClose: (value: boolean) => void
	table: string
}

export const OrderItemsSearch = ({
	onClose,
	table,
	fieldArray
}: OrderItemsSearchProps) => {
	const { viewType } = useContext(DeviceContextElement)

	const [itemSelected, setItemSelected] = useState<AddOrder.ItemsForm[]>([])
	const [availableItemsChange, setAvailableItemsChange] = useState<
		OrderItemsList[]
	>([])
	const [isLoading, setIsLoading] = useState<boolean>()

	const { register } = useForm()

	const onSelectItems = (items: any) => {
		fieldArray.append(
			items.map((item: OrderItemsList) => {
				const itemPrice = item?.price?.filter(
					(item) => item?.table === table
				)[0]?.value

				const newItemsDefaults = {
					item: '',
					code: item?.code,
					description: item?.description,
					amount: 0,
					delivery_date: '',
					embedded_freight: 0,
					discount: 0,
					table_price: item?.price || [{ value: 0, table: '' }],
					unit_price: itemPrice || 0,
					invoice: '',
					invoice_series: '',
					net_price_total: 0,
					option: '',
					storage: '',
					serial_number: '',
					has_accessories: '2',
					suframa_value: 0,
					purchase_order_item: '',
					purchase_order_number: '',
					installation_value: 0,
					accessories: [] as never,
					deleted: false
				}
				return newItemsDefaults
			})
		)
	}

	const itemsProduct = (value: string, table: string) => {
		setIsLoading(true)
		getItemsByValue(value, table)
			.then((res) => {
				const { items } = res.data
				setAvailableItemsChange(items)
				setIsLoading(false)
			})
			.catch((err) => console.log(err))
	}

	useLayoutEffect(() => {
		itemsProduct('', table)
	}, [])

	const orderItemsSearchHeader = () => {
		return (
			<div className="items-header-list">
				<div className="items-list">
					<ul className="items-list-item items-list-code">
						<span>Código</span>
					</ul>
					<ul className="items-list-item items-list-description">
						<span>Descrição</span>
					</ul>
					<ul className="items-list-item items-list-value">
						<span>Valor</span>
					</ul>
				</div>
			</div>
		)
	}

	let timer: 0 | ReturnType<typeof setTimeout> = 0
	const onSearch = async (value: string) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			itemsProduct(value?.toUpperCase(), table)
			clearTimeout(timer)
		}, 400)
	}

	function handleSelectItems(element: any) {
		const currentIndex = itemSelected.indexOf(element)
		const newChecked = [...itemSelected]

		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setItemSelected(newChecked)
	}

	const onConfirmChangeItems = () => {
		onSelectItems(itemSelected)
	}

	const orderItemsSearchBodyWeb = () => {
		return (
			<div className="items-body-list">
				{availableItemsChange?.map((item: OrderItemsList, index: number) => {
					const itemPrice = item.price.filter((item) => item.table === table)[0]
						?.value
					return (
						<div className="items-body-item" key={index}>
							<label htmlFor={`${index}`} className="items-list">
								<ul className="items-list-item items-list-code">
									<CheckBox
										id={`${index}`}
										variant="blue"
										value={item?.code}
										format="square"
										onChange={() => handleSelectItems(item)}
									/>
									<span>{item?.code}</span>
								</ul>
								<ul className="items-list-item items-list-description">
									<span>{item.description}</span>
								</ul>
								<ul className="items-list-item items-list-value">
									<span>R$ {itemPrice ? itemPrice : 0}</span>
								</ul>
							</label>
						</div>
					)
				})}
			</div>
		)
	}

	const orderItemsSearchBodyMobile = () => {
		return (
			<div className="items-body-list">
				{availableItemsChange?.map((item: OrderItemsList, index: number) => {
					const itemPrice = item.price.filter((item) => item.table === table)[0]
						?.value
					return (
						<div className="items-body-item" key={index}>
							<div className="container-checkbox">
								<CheckBox
									id={`${index}`}
									variant="blue"
									value={item?.code}
									format="square"
									onChange={() => handleSelectItems(item)}
								/>
							</div>
							<label htmlFor={`${index}`} className="items-list">
								<ul className="items-list-item items-list-code">
									<span className="items-details-list-title">Produto:</span>
									<span>{`${item?.code} - ${item.description}`}</span>
								</ul>
								<ul className="items-list-item items-list-value">
									<span className="items-details-list-title">
										Valor:
										<span>R$ {itemPrice ? itemPrice : 0}</span>
									</span>
								</ul>
							</label>
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<Modal.root>
			<Modal.header
				title="BUSCAR PRODUTO"
				onClose={() => {
					onClose(false)
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="items-list-container">
						<>
							<div className="items-input-container">
								<div>
									<Search.root>
										<Search.icon>
											<SearchIcon width="100%" height="100%" />
										</Search.icon>
										<Search.input
											placeholder="Buscar Produto"
											onChangeValues={(e) => onSearch(e)}
										/>
									</Search.root>
								</div>
							</div>
							{isLoading ? (
								<LoadingList />
							) : (
								<>
									{viewType !== ViewType.MOBILE ? (
										<>
											{orderItemsSearchHeader()}
											{orderItemsSearchBodyWeb()}
										</>
									) : (
										orderItemsSearchBodyMobile()
									)}
								</>
							)}
						</>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="items-search-action">
						<Button
							title="CONFIRMAR"
							variant="confirmation-solid"
							size="lg"
							onClick={() => {
								onConfirmChangeItems()
								onClose(false)
							}}
						/>
						<Button
							title="CANCELAR"
							variant="cancellation"
							size="lg"
							onClick={() => {
								onClose(false)
							}}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}
