import { Section } from '@praticabr/ppsa'
import React, { ReactNode } from 'react'
import './styles.scss'

export const ShippingDetailsRoot = ({ children }: { children: ReactNode }) => (
	<Section.root>
		<Section.title>Detalhes do transporte</Section.title>
		<Section.container>
			<div className="new-order-shipping-details-information">{children}</div>
		</Section.container>
	</Section.root>
)
