import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { Button, Search } from '@praticabr/ppsa'
import FilterIcon from 'assets/image/Filter.svg'
import MyFilter from 'models/Filter'
import { FilterManager } from 'module/technicalServices/components/FilterManager'
import FilterView from 'module/technicalServices/components/FilterView'
import { SelectSerial } from 'module/technicalServices/components/NewOrder/SelectSerial'
import React, { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { userInfo } from 'services'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { store } from 'store'
import {
	changeSearchTerm,
	setDefaultFilters,
	setFilterSelectedServiceOrders
} from 'store/modules/serviceOrders/actions'
import { updateModalStatus } from 'store/modules/user/actions'
import { KeyedMutator } from 'swr'
import './styles.scss'

type Props = {
	mutate: KeyedMutator<any>
	setters: {
		setFilterSelected: (value: MyFilter) => void
		setSearchTerm: (value: string) => void
		setPageNumber: (value: number) => void
		setStateChecked: (value: string[]) => void
		setStatusChecked: (value: string[]) => void
		setTypeOSChecked: (value: string[]) => void
		setTypeAccountChecked: (value: string[]) => void
		setChangeStatusFilter: (value: boolean) => void
	}
	states: {
		searchTerm: string
		filterSelected: MyFilter
		filters: MyFilter[]
	}
}

const ServiceOrderListActions = ({ setters, states, mutate }: Props) => {
	const [showSelectSerialModal, setShowSelectSerialModal] =
		useState<boolean>(false)
	const [showFilterView, setShowFilterView] = React.useState<boolean>(false)
	const [showFilterManager, setShowFilterManager] = useState<boolean>(false)
	const [userFieldControl, setUserFieldControl] = useState<boolean>(false)
	const anchorRefFilter = React.useRef<HTMLButtonElement>(null)
	const modalStatus = store.getState().user.modalStatus
	const userId = store.getState().user.profile
	const dispatch = useDispatch()

	useEffect(() => {
		userInfo(userId).then((response) => {
			response.data.id_field_control
				? setUserFieldControl(true)
				: setUserFieldControl(false)
		})
	}, [userId])

	useEffect(() => {
		if (modalStatus && userFieldControl) {
			handleNewServiceOrder()
			dispatch(updateModalStatus(false))
		}
	}, [userFieldControl])

	const handleCloseFilter = (event: React.MouseEvent<EventTarget>) => {
		if (
			anchorRefFilter.current &&
			anchorRefFilter.current.contains(event.target as HTMLElement)
		) {
			return
		}
		setShowFilterManager(false)
	}

	const clearAllFilters = () => {
		const eraseFilter: any = ''
		setters.setStateChecked([])
		setters.setStatusChecked([])
		setters.setTypeOSChecked([])
		setters.setTypeAccountChecked([])
		setters.setFilterSelected(eraseFilter)
		setShowFilterManager(false)
		dispatch(setDefaultFilters())
	}

	const handleFilterView = () => {
		setShowFilterManager(false)
		setShowFilterView(true)
	}

	const applyFilterSelected = (filter: MyFilter) => {
		setters.setStateChecked(filter.stateList)
		setters.setStatusChecked(filter.statusList)
		setters.setTypeOSChecked(filter.typeOsList)
		setters.setTypeAccountChecked(filter.accountTypeList)
		setShowFilterManager(false)
		setters.setFilterSelected(filter)
		dispatch(setFilterSelectedServiceOrders(filter))
	}

	const handleNewServiceOrder = () => {
		if (userFieldControl) {
			setShowSelectSerialModal(true)
		} else {
			toast.error(
				'Usuário sem integração, favor contatar administrador do sistema.'
			)
		}
	}

	return (
		<div className="all-service-orders-actions">
			<div className="service-orders-search-and-filter">
				<div id="joyride-searchbar-servorders">
					<Search.root>
						<Search.icon>
							<SearchIcon height="100%" width="100%" />
						</Search.icon>
						<Search.input
							placeholder="Procurar ordem de serviço"
							defaultValue={states.searchTerm}
							onChangeValues={(value) => {
								setters.setPageNumber(1)
								setters.setSearchTerm(value)
								dispatch(changeSearchTerm(value))
							}}
						/>
					</Search.root>
				</div>
				<button
					id="joyride-filterbutton-servorders"
					style={{ backgroundColor: 'transparent' }}
					onClick={() => setShowFilterManager((prevOpen) => !prevOpen)}
					ref={anchorRefFilter}
					aria-controls={showFilterManager ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
				>
					<img src={FilterIcon} alt="filter icon" />
				</button>
				<Popper
					className="all-service-orders-filter-manager-contain"
					style={{ zIndex: 1 }}
					open={showFilterManager}
					anchorEl={anchorRefFilter.current}
					role={undefined}
					keepMounted={true}
				>
					<ClickAwayListener onClickAway={handleCloseFilter}>
						<Paper className="all-service-orders-filter-manager-contain">
							<FilterManager
								show={showFilterManager}
								filters={states.filters}
								selected={states.filterSelected}
								onClear={clearAllFilters}
								onCreate={handleFilterView}
								onSelect={applyFilterSelected}
							/>
						</Paper>
					</ClickAwayListener>
				</Popper>
			</div>
			<Button
				size="lg"
				title="NOVO ATENDIMENTO"
				variant="confirmation-solid"
				onClick={handleNewServiceOrder}
			/>
			{showSelectSerialModal && (
				<SelectSerial
					show={showSelectSerialModal}
					onClose={setShowSelectSerialModal}
					mutate={mutate}
				/>
			)}
			{showFilterView && (
				<FilterView
					handleChanges={setters.setChangeStatusFilter}
					onClose={setShowFilterView}
				/>
			)}
		</div>
	)
}

export default ServiceOrderListActions
