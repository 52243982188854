import { Radio } from '@praticabr/ppsa'
import {
	OrderPendencyStatusLabels,
	OrderPendencyStatusTypes
} from 'module/orderManagement/utils/constants'
import React from 'react'
import './styles.scss'

interface Params {
	pendencyStatus?: string
	onPendencyStatusSelected: (statusList: string) => void
}

const PendencyField: React.FC<Params> = ({
	pendencyStatus,
	onPendencyStatusSelected
}) => {
	const isChecked = (value: string) => {
		if (pendencyStatus?.length) {
			return pendencyStatus.includes(value)
		}
		return false
	}

	const onClick = (statusSelected: string) => {
		onPendencyStatusSelected(statusSelected)
	}

	return (
		<div className="pendency-filter">
			<div className="pendency-filter-header">
				<span>Pendência</span>
			</div>

			<form className="pendency-filter-fields">
				<div>
					<Radio
						type="radio"
						variant="blue"
						id="pendency"
						checked={isChecked(OrderPendencyStatusTypes.PENDENCY)}
						onChange={() => onClick(OrderPendencyStatusTypes.PENDENCY)}
						name={OrderPendencyStatusLabels.PENDENCY}
					/>
				</div>
				<div>
					<Radio
						type="radio"
						variant="blue"
						id="not-pendency"
						checked={isChecked(OrderPendencyStatusTypes.NOT_PENDENCY)}
						onChange={() => onClick(OrderPendencyStatusTypes.NOT_PENDENCY)}
						name={OrderPendencyStatusLabels.NOT_PENDENCY}
					/>
				</div>
			</form>
		</div>
	)
}

export default PendencyField
