import { Button, Paginator, Search } from '@praticabr/ppsa'
import down from 'assets/image/arrow_downward-24px.svg'
import up from 'assets/image/arrow_upward-24px.svg'
import $ from 'jquery'
import PrevContracts from 'models/PrevContracts'
import PrevSchedules from 'models/PrevSchedules'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import AddPreventive from 'module/technicalServices/components/AddPreventive'
import { AllSchedules } from 'module/technicalServices/components/AllSchedules'
import Contract from 'module/technicalServices/components/Prev_Contracts'
import ScheduleOs from 'module/technicalServices/components/ScheduleOs'
import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	getPrevContracts,
	getPrevSchedules,
	isTokenValid
} from 'services/index'
import MobileNavigator from 'shared/components/MobileNavigator'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { store } from 'store'
import { signOut } from 'store/modules/auth/actions'
import { ViewType } from 'utils/Constants'
import { printConsoleError } from 'utils/consoles'
import { Abas, ContractContent, Header, ScheduleContent } from './styles'
import './styles.css'
import './styles.scss'

//

const LIMIT = 20
const CONTRACTS_TAB = 1
const SCHEDULE_TAB = 2

type TParams = { history: string; clientCode: string }

interface FilterItem {
	id: number
	name: string
}

const Equipments: React.FC<RouteComponentProps<TParams>> = ({
	history,
	match
}: RouteComponentProps<TParams>) => {
	const { viewType } = useContext(DeviceContextElement)
	const [contracts, setContracts] = useState<PrevContracts[]>([])
	const [contractsToShow, setContractsToShow] = useState<PrevContracts[]>([])
	const [osSchedules, setOsSchedules] = useState<PrevSchedules[]>([])
	const [originalOsSchedules, setOriginalOsSchedules] = useState<
		PrevSchedules[]
	>([])
	const [osSchedulesSelected, setOsSchedulesSelected] = useState<
		PrevSchedules[]
	>([])
	const [selectedScheduleButton, setSelectedScheduleButton] =
		React.useState<boolean>(false)
	const [worked, setWorked] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingSchedules, setLoadingSchedules] = useState<boolean>(false)
	const [message, setMessage] = useState<string>('')
	const [prevSearch, setPrevSearch] = useState<string>('')
	const [hasChange, setHasChange] = useState<boolean>(false)
	const [contractSearch, setContractSearch] = React.useState<string>('')
	const [totalContractPages, setTotalContractPages] = React.useState<number>(0)
	const [pageContractNumber, setPageContractNumber] = React.useState<number>(1)
	const [totalSchedulesPages, setTotalSchedulesPages] =
		React.useState<number>(0)
	const [pageSchedulesNumber, setPageSchedulesNumber] =
		React.useState<number>(1)
	const [tabSelected, setTabSelected] = React.useState<number>(CONTRACTS_TAB)
	const [searchValue, setSearchValue] = React.useState<string>('')
	const [orderNextPreventive, setOrderNextPreventive] =
		React.useState<boolean>(false)
	const [fieldFilter, setFieldFilter] = React.useState<string>('id')
	const [orderFilter, setOrderFilter] = React.useState<string>('asc')

	const dispatch = useDispatch()

	useEffect(() => {
		const callOpenApi = async () => {
			await requestAllPrevContracts()
		}

		callOpenApi()
	}, [history, match])

	useEffect(() => {
		if (hasChange) {
			requestAllPrevContracts(pageContractNumber)
			setHasChange(false)
		}
	}, [hasChange])

	useEffect(() => {
		requestAllPrevContracts(pageContractNumber)
	}, [pageContractNumber])

	useEffect(() => {
		requestAllPrevSchedules(pageSchedulesNumber)
	}, [pageSchedulesNumber])

	useEffect(() => {
		requestAllPrevContracts()
		return () => {
			setPageContractNumber(1)
		}
	}, [contractSearch])

	useEffect(() => {
		requestAllPrevSchedules()
		return () => {
			setPageContractNumber(1)
		}
	}, [prevSearch])

	useEffect(() => {
		requestAllPrevSchedules()
	}, [orderNextPreventive, orderFilter, fieldFilter])

	const requestAllPrevContracts = async (page = 1) => {
		setLoading(true)
		const offset = (page - 1) * LIMIT
		await getPrevContracts(contractSearch, offset, LIMIT)
			.then((res) => {
				const { response } = res.data
				setTotalContractPages(Math.ceil(response.count / LIMIT))
				setContracts(response.contracts)
				setContractsToShow(response.contracts)
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
				printConsoleError(error.response)
				printConsoleError(error.request)
				printConsoleError(error.message)
				if (error.message === 'Network Error') {
					history.push('/error/500') // Network Error
				} else if (error.response.status === 404) {
					history.push('/error/404')
				} else if (error.response.status === 401) {
					isTokenValid(store.getState().auth.token)
						.then((resp) => {
							if (resp.data.exists === true) {
								history.push('/home')
							} else {
								dispatch(signOut())
								history.push('/')
							}
						})
						.catch((err) => {
							dispatch(signOut())
							history.push('/')
						})
				}
			})
	}

	const requestAllPrevSchedules = async (page = 1) => {
		setLoading(true)
		const offset = (page - 1) * LIMIT
		const pageInfo = { offset, LIMIT, prevSearch, fieldFilter, orderFilter }
		await getPrevSchedules(pageInfo)
			.then((resp) => {
				const { data } = resp
				setTotalSchedulesPages(Math.ceil(data.count / LIMIT))
				setOriginalOsSchedules(data.schedules)
				setOsSchedules(data.schedules)
				setLoading(false)
				setLoadingSchedules(true)
			})
			.catch((error) => {
				setLoading(false)
				printConsoleError(error.response)
				printConsoleError(error.request)
				printConsoleError(error.message)
				if (error.message === 'Network Error') {
					history.push('/error/500') // Network Error
				} else if (error.response.status === 404) {
					history.push('/error/404')
				} else if (error.response.status === 401) {
					isTokenValid(store.getState().auth.token)
						.then((resp) => {
							if (resp.data.exists === true) {
								history.push('/home')
							} else {
								dispatch(signOut())
								history.push('/')
							}
						})
						.catch((err) => {
							dispatch(signOut())
							history.push('/')
						})
				}
			})
	}

	const handleSchedules = (schedules: PrevSchedules[]) => {
		setOsSchedulesSelected(schedules)
	}

	const onClickSchedule = () => {
		setSelectedScheduleButton(true)
		if (osSchedulesSelected?.length !== 0) {
			$('.scheduleOs').toggle()
			$('body').addClass('modal-open')
		}
	}

	const handleCancel = () => {
		$('.scheduleOs').toggle()
		setOsSchedulesSelected([])
		setPageSchedulesNumber(1)
		window.location.reload()
	}

	const handleChanges = (worked: boolean) => {
		if (worked) {
			const promise = [
				setOsSchedulesSelected([]),
				setPageContractNumber(1),
				requestAllPrevSchedules(),
				setWorked(true)
			]
			Promise.all(promise)
				.then((response) => {
					toast.success('Visitas de preventiva agendadas com sucesso!')
					setHasChange(!hasChange)
					setLoadingSchedules(false)
				})
				.catch((error) => {
					toast.error('Erro ao agendar visitas preventivas!')
					console.log(error)
				})
		} else {
			setWorked(false)
			setMessage('fail')
		}
	}

	let timer = 0
	const onSearch = async (value: string) => {
		clearTimeout(timer)
		timer = window.setTimeout(() => {
			searchData(value)
			clearTimeout(timer)
		}, 600)
	}

	const searchData = async (value: string) => {
		setSearchValue(value)
		if (tabSelected === SCHEDULE_TAB) {
			setPrevSearch(value)
		} else {
			setContractSearch(value)
		}
	}

	const onSearchIconClick = (value: string) => {
		if (value) {
			setSearchValue('')
			if (tabSelected === SCHEDULE_TAB) {
				setPrevSearch('')
			} else {
				setContractSearch('')
			}
			$('.search-contract-field').find('input').val('')
		}
	}

	const handleSchedulesPagination = (page: number) => {
		setPageSchedulesNumber(page)
	}

	const handleTabsChange = (tab: number) => {
		if (!loading) {
			setTabSelected(tab)
		}

		if (tab === SCHEDULE_TAB) {
			setOsSchedulesSelected([])
			setPageSchedulesNumber(1)
		} else {
			setPageContractNumber(1)
		}
	}

	const tabsContent = () => {
		return (
			<Abas>
				<div
					className={`tab-item ${
						tabSelected === CONTRACTS_TAB ? `selected` : ``
					}`}
				>
					<a
						onClick={() => {
							handleTabsChange(CONTRACTS_TAB)
						}}
					>
						Contratos
					</a>
				</div>
				<div
					className={`tab-item ${
						tabSelected === SCHEDULE_TAB ? `selected` : ``
					}`}
				>
					<a
						onClick={() => {
							handleTabsChange(SCHEDULE_TAB)
						}}
					>
						Agendamentos
					</a>
				</div>
			</Abas>
		)
	}

	const searchContractContent = (value: string) => {
		return (
			<div className="search-area">
				<Search.root>
					<Search.icon>
						<SearchIcon height="100%" width="100%" />
					</Search.icon>
					<Search.input
						placeholder="Pesquisar"
						onChangeValues={(value) => onSearch(value)}
					/>
				</Search.root>
			</div>
		)
	}

	const contractsListContent = () => {
		return !contractsToShow?.length ? (
			<div className="empty-list">Nenhum contrato de preventiva encontrado</div>
		) : (
			<>
				<div className="table constracts">
					<div className="table-header contracts">
						<div className="contracts-table-header-item-razao">
							Razão Social
						</div>
						<div className="contracts-table-header-item-cnpj">CNPJ</div>
						<div className="contracts-table-header-item-end-contract">
							Fim do Contrato
						</div>
						<div className="contracts-table-header-item-equip">Equip.</div>
					</div>
					<div className="table-list">
						{contractsToShow.map((item) => (
							<Contract
								key={item.id}
								prevContract={item}
								hasUpdated={() => {
									setHasChange(true)
								}}
							/>
						))}
					</div>
				</div>
				{totalContractPages >= 1 && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<Paginator
							onPageChange={(e) => handleSchedulesPagination(e)}
							currentPage={pageSchedulesNumber}
							totalPages={totalSchedulesPages}
						/>
					</div>
				)}
			</>
		)
	}

	const constractsContent = () => {
		return (
			<div>
				{tabSelected === CONTRACTS_TAB ? (
					<ContractContent className="contractContent">
						{searchContractContent(contractSearch)}
					</ContractContent>
				) : (
					<></>
				)}
			</div>
		)
	}

	const schedulesListContent = () => {
		return !osSchedules?.length ? (
			<div className="empty-list">Nenhum contrato de preventiva encontrado</div>
		) : (
			<>
				<div className="table schedules">
					<div className="table-header schedules">
						<div className="schedules-table-header-item-client">Cliente</div>
						<div className="schedules-table-header-item-equip">Equipamento</div>
						<div className="schedules-table-header-item-serial">N/S</div>
						<div
							style={{ display: 'inline' }}
							className="schedules-table-header-item-prev"
						>
							<div>
								Previsão
								<a
									onClick={() => {
										setOrderNextPreventive(!orderNextPreventive)
										setFieldFilter('next_preventive')
										setOrderFilter(orderNextPreventive ? 'desc' : 'asc')
									}}
								>
									{orderNextPreventive ? (
										<img
											id=""
											style={{
												height: '20px',
												width: '20px',
												paddingLeft: '5px'
											}}
											src={up}
										/>
									) : (
										<img
											id=""
											style={{
												height: '20px',
												width: '20px',
												paddingLeft: '5px'
											}}
											src={down}
										/>
									)}
								</a>
							</div>
						</div>
						<div className="schedules-table-header-item-location">
							Localização
						</div>
					</div>
					<div className="table-list">
						<AllSchedules
							schedules={osSchedules}
							handleSchedules={handleSchedules}
							loadingSchedules={loadingSchedules}
							selectedSchedules={osSchedulesSelected}
						/>
					</div>
				</div>
				{totalSchedulesPages >= 1 && (
					<Paginator
						onPageChange={(e) => handleSchedulesPagination(e)}
						currentPage={pageSchedulesNumber}
						totalPages={totalSchedulesPages}
					/>
				)}
			</>
		)
	}

	const schedulesContent = () => {
		return (
			<div>
				{tabSelected === SCHEDULE_TAB ? (
					<ScheduleContent className="scheduleContent">
						<div className="contractContent">
							{searchContractContent(prevSearch)}
						</div>
					</ScheduleContent>
				) : (
					<></>
				)}
			</div>
		)
	}

	const listContractSchedules = () => {
		return tabSelected === CONTRACTS_TAB ? (
			<>
				{loading ? (
					<Skeleton count={15} height={28.2} />
				) : (
					contractsListContent()
				)}
			</>
		) : (
			<>
				{loading ? (
					<Skeleton count={15} height={28.2} />
				) : (
					schedulesListContent()
				)}
			</>
		)
	}

	return (
		<>
			<Header>
				{viewType === ViewType.MOBILE && (
					<MobileNavigator action={() => history.push('/home')} />
				)}
				Manutenção Preventiva
			</Header>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: '5px',
					flexDirection: viewType === ViewType.MOBILE ? 'column' : 'row'
				}}
			>
				<div style={{ height: '35px', float: 'right' }}>
					{constractsContent()}
					{schedulesContent()}
				</div>
				{tabsContent()}
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div
					className="add-preventive-contract"
					onClick={() => {
						if (viewType === ViewType.MOBILE) {
							toast.error(
								'Adicionar novo contrato não pode ser acessados via mobile'
							)
						} else {
							$('.createPreventive').toggle()
						}
					}}
				>
					Adicionar novo contrato
				</div>
				<div>
					{!!osSchedulesSelected?.length && (
						<div className="schedule-field">
							<Button
								title="Agendar"
								onClick={onClickSchedule}
								variant="confirmation-solid"
								size="md"
							/>
							<small>
								{selectedScheduleButton &&
									osSchedulesSelected?.length === 0 && (
										<span
											style={{
												color: 'red',
												display: 'flex',
												marginTop: '10px',
												marginLeft: '10px'
											}}
										>
											Selecione ao menos um equipamento.
										</span>
									)}
							</small>
						</div>
					)}
				</div>
			</div>
			<div className="contracts-table">{listContractSchedules()}</div>
			{loadingSchedules && (
				<div
					className="modal scheduleOs"
					style={{ padding: '35px 75px', overflow: 'hidden' }}
				>
					<ScheduleOs
						handleCancel={handleCancel}
						handleChanges={handleChanges}
						schedules={osSchedulesSelected}
					/>
				</div>
			)}
			<div className="modal createPreventive">
				<AddPreventive />
			</div>
		</>
	)
}

export default Equipments
